/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [{
        url: "/dashboard",
        name: "Dashboard",
        icon: "HomeIcon"
    },

    {
        url: null,
        name: "Payment",
        tag: "2",
        tagColor: "warning",
        icon: "CreditCardIcon",
        submenu: [{
                url: "/payment/invesments",
                name: "Invesments",
                slug: "payment-invesment"
            },
            {
                url: "/payment/benefits",
                name: "Benefits",
                slug: "dashboard-benefits"
            }
        ]
    },
    {
        url: "/withdraws",
        name: "Withdraws",
        icon: "ArrowDownCircleIcon"
    },
    {
        url: "/network",
        name: "Network",
        icon: "Share2Icon"
    },
    {
        url: "/freeze-ecg",
        name: "Freeze ECG",
        icon: "CloudSnowIcon"
    },
    // {
    //     url: "/exchange",
    //     name: "Exchange",
    //     icon: "DollarSignIcon",
    // },
    //{
    //    url: "/sync-ecs",
    //    name: "Sync ECS",
    //    icon: "RefreshCwIcon"
    //},
    {
        url: "/support-center",
        name: "Support Center",
        icon: "MessageSquareIcon"
    },
    // {
    //     url: "/migrate",
    //     name: "Migrate",
    //     icon: "CreditCardIcon"
    // },

    // {
    //     url: null,
    //     name: "Support Center",
    //     tag: "2",
    //     tagColor: "warning",
    //     icon: "MessageSquareIcon",
    //     submenu: [{
    //             url: "/guideline",
    //             name: "Guideline",
    //             slug: "guideline"
    //         },
    //         {
    //             url: "/migrate-ticket",
    //             name: "Migrate Ticket",
    //             slug: "migrate-ticket"
    //         }
    //     ]
    // },
    {
        url: "/faqs",
        name: "FAQs",
        icon: "HelpCircleIcon"
    }
];
